<template>
  <div class="auth-content align-center">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="" />
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="" />
    <div class="auth-wrap">
      <img src="~assets/images/auth/auth-bg.png" class="auth-bg" alt="" />
      <img src="~assets/images/auth/auth-logo.png" class="auth-logo" @click="linkHome" alt="" />
      <p class="auth-hint-p">医生为本，取之于医，用之于医</p>
      <!--  输入内容  -->
      <div class="auth-inp-box">
        <!-- 下载app -->
        <div class="app-hint-box">
          <p class="app-tit">君莲书院APP</p>
          <div class="app-info-wrap">
            <div class="app-info-box align-center">
              <div class="app-info-left">
                <img class="code-img" src="https://static.helixlife.cn/circle/images/download-app.png" alt="" />
              </div>
              <div class="app-info-right">
                <div class="down-app-box ios-btn-box vertical-center">
                  <img src="~assets/images/auth/ios-icon.png" class="ios" alt="" />
                  <img src="~assets/images/auth/ios-icon-h.png" class="ios-h" alt="" />
                  <p>App Store下载</p>
                </div>
                <div class="down-app-box android-btn-box vertical-center">
                  <img src="~assets/images/auth/android-icon.png" class="android" alt="" />
                  <img src="~assets/images/auth/android-icon-h.png" class="android-h" alt="" />
                  <p>Android下载</p>
                </div>
                <p class="app-hint">扫描二维码下载APP</p>
              </div>
              <div class="app-info-occupied"></div>
            </div>
          </div>
        </div>
        <!--  扫码按钮    -->
        <!--      <img src="~assets/images/auth/auth-wechat-code.png" class="auth-wechat-code" @click="loginType" alt="">-->
        <p class="auth-inp-tit">账号登录</p>

        <!--  密码输入信息开始    -->
        <div class="auth-inp-cont" v-if="wechat_pad">
          <div class="auth-inp-item vertical-center">
            <img src="~assets/images/auth/auth-mobile-icon.png" class="inp-icon" alt="" />
            <el-input type="text" placeholder="请输入手机号/邮箱" v-model.trim="username" maxlength="20" class="auth-inp" />
            <p class="error-hint-p" v-if="username_err">请输入正确的手机号/邮箱</p>
          </div>
          <div class="auth-inp-item vertical-center">
            <img src="~assets/images/auth/auth-code-icon.png" class="inp-icon" alt="" style="width: 16px" />
            <el-input :type="password_show_status ? 'text' : 'password'" placeholder="请输入密码" v-model.trim="password" maxlength="20" class="auth-inp" />
            <!-- <p class="error-hint-p" v-if="password_err">请输入密码</p> -->
            <div class="error-hint-p msg_p"><img src="~assets/images/auth/warning.png" mode="scaleToFill" /><span> 请输入密码8-20位，大小写字母、数字、符号至少三种 </span></div>
            <div class="pad-show-icon" @click="padShowStatus" v-if="password_show_status"></div>
            <div class="pad-hide-icon" @click="padShowStatus" v-else></div>
          </div>
          <div class="auth-set-box space-between">
            <div class="auth-set-btn">
              <el-checkbox v-model="auth_keep_status" style="color: #93999f">自动登录</el-checkbox>
            </div>
            <p class="forget-p" @click="forgetPad">忘记密码？</p>
          </div>

          <div class="login-btn text-select-none" @click="loginSubmit" v-if="login_submit_status">登录</div>
          <div class="login-btn text-select-none" v-else>登录中 <i class="el-icon-loading"></i></div>
          <!--        <div class="space-between">-->
          <!--          <router-link tag="div" to="/register" class="reg-hint-link">注册君莲书院</router-link>-->
          <!--          <div  class="reg-hint-link"  @click="wechat_pad=false">验证码登录</div>-->
          <!--        </div>-->
          <div class="other-btn align-center">
            <p @click="wechat_pad = false">手机验证码登录</p>
            <p class="line" style="cursor: auto">|</p>
            <p @click="registerLink">注册君莲书院</p>
          </div>
        </div>
        <!--  密码输入信息结束  -->

        <div v-else>
          <!--  微信登录扫码开始    -->
          <div class="wechat-login-box" v-if="wechat_pad">
            <div class="wechat-code-box">
              <img src="https://image.helixlife.cn/edu-cn/course/202107/YGIigjGNzRbhNJ2zYWmRhEPpL7m3UWpkRcOoyHKs.png" class="wechat-code" alt="" />
              <!-- 刷新  -->
              <div class="refresh-code-box">
                <div class="refresh-code-cont align-center">
                  <div>
                    <p class="code-hint">二维码失效</p>
                    <p class="refresh-btn">刷新</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="other-btn align-center">
              <p @click="padLogin">密码登录</p>
              <p class="line">|</p>
              <p @click="registerLink">注册新账号</p>
            </div>
          </div>
          <!--  微信登录扫码结束    -->

          <!--  验证码绑定开始    -->
          <div class="auth-inp-cont" v-else>
            <div class="auth-inp-item vertical-center">
              <img src="~assets/images/auth/auth-mobile-icon.png" class="inp-icon" alt="" />
              <el-input type="text" placeholder="手机号" maxlength="20" class="auth-inp" v-model.trim="mobilename" />
              <p class="error-hint-p" v-if="mobile_err">请输入正确的手机号</p>
            </div>
            <div class="auth-inp-item code-auth-inp-item vertical-center">
              <img src="~assets/images/auth/auth-code-icon.png" class="inp-icon" alt="" style="width: 16px" />
              <el-input type="text" placeholder="请输入验证码" maxlength="20" class="auth-inp" v-model.trim="code" v-if="!time_status" />
              <p v-else style="font-size: 14px; color: #b7bbbf; margin-left: 10px">请输入验证码</p>
              <div class="code-box" @click="getMobileCode()" v-if="time_status"><p>获取验证码</p></div>
              <div class="code-box code-boxs" v-else>{{ time }}秒后重新获取</div>
              <p class="error-hint-p" v-if="code_err">请输入验证码</p>
            </div>

            <div class="login-btn forget-submit-btn" @click="codeLogin()" v-if="login_submit_status">登录</div>
            <div class="login-btn forget-submit-btn" v-else>登录中 <i class="el-icon-loading"></i></div>
            <div class="other-btn align-center">
              <p @click="padLogin">密码登录</p>
              <p class="line">|</p>
              <p @click="registerLink">注册君莲书院</p>
            </div>
          </div>
          <!--  验证码登录结束    -->
        </div>
      </div>
    </div>
    <div class="bottom-hint-box align-center">
      {{ $static.footer_text }}
      <a href="https://beian.miit.gov.cn" target="_blank" class="records-link">{{ $static.record_no }}</a>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import user from '../../utils/user';
import store from '../../store/store';
import router from '../../router/index';
export default {
  name: 'Login',
  data() {
    return {
      auth_keep_status: false, // 自动登录状态
      wechat_pad: false, // 微信扫码和密码登录切换
      login_submit_status: true, // 登录提交状态
      username: '', // 手机号/邮箱
      password: '', // 密码，至少6位
      username_err: false,
      password_err: false,
      scan_qr_code: true, // 微信扫码或绑定
      password_show_status: false,
      mobilename: '', //验证码手机
      time: 120, //验证码获取时间
      time_status: true, //
      mobile_err: false,
      mobilekey: '',
      code: '', //验证码
      code_err: false,
      day: 0, // 过期天数
      minute: 0, // 过期分钟
      second: 0, // 锁定次数
    };
  },
  created() {
    this.getConfigs();
  },
  mounted() {
    let _this = this;
    document.onkeydown = function (e) {
      // 回车提交表单
      var theEvent = window.event || e;
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode;
      if (code == 13 && _this.$route.name === 'login') {
        if (_this.wechat_pad) {
          _this.loginSubmit();
        }
      }
    };
  },
  methods: {
    // 获取配置
    getConfigs() {
      let url = `${this.$api.loginConfigs}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          let info = res.data.data;
          this.day = Number(info.day);
          this.minute = Number(info.minute);
          this.second = Number(info.second);
          localStorage.setItem('day', this.day);
          console.log(this.day, this.minute, this.second);
        }
      });
    },
    codeLogin: _.debounce(function () {
      this.mobile_err = false;
      this.code_err = false;
      if (this.mobilename.trim() === '') {
        this.mobile_err = true;
        return false;
      }
      if (this.code.trim() === '') {
        this.code_err = true;
        return false;
      }
      this.login_submit_status = false;
      let url = this.$api.Login;
      let data = {
        username: this.mobilename.trim(),
        grant_type: 'verity',
        source: 'pc',
        verification_key: this.mobilekey,
        verification_code: this.code.trim(),
      };
      // let newTime = new Date();
      // let updataTime = localStorage.getItem('updataTime');
      // var rangeDateNum = (newTime.getTime() - updataTime) / (1000 * 3600 * 24);
      // if (rangeDateNum > this.day) {
      //   this.$router.push('/overdue');
      // } else {
      this.$http
        .post(url, data)
        .then((res) => {
          if (res.data.success) {
            this.$cache.setLoginInfo(res.data.data);
            this.getCircles();
            //this.$user.getUserInfo()
            /*setTimeout(()=>{
              this.$router.replace('/')
            },500)*/
          } else {
            this.login_submit_status = true;
            this.$message.error(res.data.message);
            // if (res.data.message == '长时间未修改密码，请前往修改密码') {
            //   this.$router.push('/overdue');
            // }
          }
        })
        .catch((error) => {
          this.login_submit_status = true;
          this.$message.error(error.response.data.message);
          // if (error.response.data.message == '长时间未修改密码，请前往修改密码') {
          //   console.log('======');
          //   this.$router.push('/overdue');
          // }
        });
      // }
    }, 500),
    //获取手机验证码
    getMobileCode: _.debounce(function () {
      this.MobileCode();
    }, 500),
    // 验证码
    MobileCode() {
      let url = this.$api.verification;
      let data = {
        mobile: this.mobilename.trim(),
        type: 'login',
      };
      this.$http
        .post(url, data, true)
        .then((res) => {
          if (res.data.success) {
            this.$message.success('验证码已发送，请注意查收');
            this.mobilekey = res.data.data.key;
            this.mobile_err = false;
            this.time_status = false;
            this.setIntervalFun();
          } else {
            this.mobile_err = true;
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.mobile_err = true;
          this.$message.error(error.response.data.message);
        });
    },
    // 计时器
    setIntervalFun() {
      this.timer = setInterval(() => {
        if (this.time === 0) {
          this.time_status = true;
          this.time = 120;
          clearInterval(this.timer);
          return;
        }
        this.time--;
      }, 1000);
    },
    // 扫码和密码切换
    loginType: _.debounce(function () {
      this.wechat_pad = !this.wechat_pad;
    }, 500),
    // 账号密码登录
    loginSubmit: _.debounce(function () {
      this.username_err = false;
      // this.password_err = false;
      if (this.username.trim() == '') {
        this.username_err = true;
        return false;
      }

      // if (this.password.trim() == '' || this.password.trim().length < 6) {
      //   // this.password_err = true;
      //   return false;
      // }
      this.login_submit_status = false;
      let url = this.$api.Login;
      let data = {
        username: this.username.trim(),
        password: this.password.trim(),
        grant_type: 'password',
        source: 'pc',
      };
      console.log(data);
      // let newTime = new Date();
      // let updataTime = localStorage.getItem('updataTime');
      // var rangeDateNum = (newTime.getTime() - updataTime) / (1000 * 3600 * 24);
      // console.log(rangeDateNum, '=============================');

      // if (rangeDateNum < this.day) {
      //   this.$router.push('/overdue');
      // } else {
      this.$http
        .post(url, data)
        .then((res) => {
          if (res.data.success) {
            this.$cache.setLoginInfo(res.data.data);
            this.getCircles();
            //this.$user.getUserInfo()
            /*setTimeout(()=>{
            this.$router.replace('/')
          },600)*/
          } else {
            this.login_submit_status = true;
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.login_submit_status = true;
          this.$message.error(error.response.data.message);
          // if (error.response.data.message == '长时间未修改密码，请前往修改密码') {
          //   console.log('======');
          //   this.$router.push('/overdue');
          // }
        });
      // }
    }, 200),
    // 忘记密码
    forgetPad() {
      this.$router.push({ path: '/forget' });
    },
    // 密码登录
    padLogin() {
      this.username_err = false;
      // this.password_err = false;
      this.mobile_err = false;
      this.code_err = false;

      this.wechat_pad = true;
    },
    // 注册新账号
    registerLink() {
      this.$router.push('/register');
    },
    // 密码展示状态
    padShowStatus() {
      this.password_show_status = !this.password_show_status;
    },
    // 首页跳转
    linkHome() {
      this.$router.push('/');
    },
    // 获取小组列表
    getCircles() {
      let url = `${this.$api.userManagedCircles}?is_author=1`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          let _data = res.data.data;
          if (_data.length) {
            if (_data.length == 1) {
              localStorage.setItem('circle-uuid', _data[0].uuid);
              let time = new Date().getTime();
              setTimeout(() => {
                // this.$user.getUserInfo();
                this.getUserInfo(time);
              }, 200);
            } else {
              console.log('==================');
              this.$router.push({
                path: '/verify',
                query: {
                  wechat_pad: this.wechat_pad,
                },
              });
            }
          } else {
            this.$router.replace('/perfect');
          }
        }
      });
    },
    getUserInfo(time) {
      store.dispatch('A_USER_INFO', {});
      var day = Number(localStorage.getItem('day'));
      this.$http
        .get(this.$api.userInfo, true)
        .then((res) => {
          if (res.data.success) {
            store.dispatch('A_USER_INFO', res.data.data);
            let user_info = res.data.data;
            let password_at = user_info.updated_password_at;
            var rangeDateNum = (time - password_at) / (1000 * 3600 * 24);
            console.log(rangeDateNum, '==========================');
            console.log(day, 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
            if (rangeDateNum > day && this.wechat_pad) {
              this.$router.push('/overdue');
            } else {
              if (user_info.managed_circle == null || user_info.managed_circle.info == null) {
                if (router.history.current.name === 'perfect') {
                } else {
                  // router.replace({ path: '/perfect' });
                  // return false;
                }
              } else {
                if (user_info.managed_circle.status === 0 || user_info.managed_circle.status === -1) {
                  if (router.history.current.name === 'perfect') {
                  } else {
                    router.replace({ path: '/audit' });
                    return false;
                  }
                }
              }
              let route_name = router.history.current.name;
              if (route_name === 'login' || route_name === 'register' || route_name === 'forget' || route_name === 'verify') {
                if (user_info.managed_circle.status === -1 || user_info.managed_circle.status === 0) {
                  router.replace({ path: '/audit' });
                } else {
                  router.replace({ path: '/home' });
                }
              }
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            cache.removeLoginInfo();
            router.replace({ path: '/login' });
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/auth/auth.less';
.bottom-hint-box {
  a {
    margin-left: 6px;
    color: #93999f;
    text-decoration: none;
  }
}
</style>
